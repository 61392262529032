/** @define visual */

.visual {
  align-items: center;
  display: flex;
  height: auto;
  justify-content: center;
  max-height: 772px;
  overflow: hidden;
  position: relative;
  width: 100%;
  @include media-breakpoint-down(md) {
    display: block;
  }

  &__img {
    display: block;
    height: auto;
    width: 100%;
  }
}

/** @define visual-caption */
.visual-caption {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 100%;
  @include media-breakpoint-down(md) {
    padding-bottom: 10px;
    position: static;
  }

  &__title {
    @include font-size (102px, 105px);
    color: #fff;
    display: block;
    font-family: $otari;
    font-weight: bold;
    left: 2px;
    letter-spacing: -3px;
    position: relative;
    top: 14px;
    @include media-breakpoint-down(lg) {
      @include font-size (80px, 85px);
    }
    @include media-breakpoint-down(md) {
      @include font-size (60px, 65px);
      color: #636464;
    }
    @include media-breakpoint-down(sm) {
      @include font-size (40px, 45px);
      color: #636464;
    }
  }

  &__desc {
    @include font-size (18px, 20px);
    color: #fff;
    display: block;
    left: -6px;
    letter-spacing: 0.1px;
    position: relative;
    top: 10px;
    @include media-breakpoint-down(lg) {
      @include font-size (16px, 18px);
    }
    @include media-breakpoint-down(md) {
      @include font-size (15px, 17px);
      color: #636464;
      left: 0;
    }
  }
}

/** @define promo */
.promo {
  background: #a2c15c;
  border-bottom: 6px solid #fffb9a;
  color: #fff;
  margin-bottom: 71px;
  padding: 43px 0 33px;
  position: relative;
  @include media-breakpoint-down(lg) {
    margin-bottom: 51px;
  }
  @include media-breakpoint-down(md) {
    margin-bottom: 31px;
  }

  &__head {
    @include font-size (50px, 52px);
    font-family: $otari;
    letter-spacing: -0.9px;
    margin-bottom: 0;
    text-indent: 17px;
    @include media-breakpoint-down(lg) {
      @include font-size (40px, 42px);
      letter-spacing: 0;
      margin-bottom: 15px;
      text-align: center;
      text-indent: 0;
    }
    @include media-breakpoint-down(md) {
      @include font-size (35px, 39px);
    }    
    @include media-breakpoint-down(sm) {
      @include font-size (30px, 34px);
    }

    &--btn {
      @include font-size (18px, 20px);
      background: #41653d;
      border-radius: 30px;
      color: #fff;
      display: inline-block;
      font-family: $proximasoft;
      height: 44px;
      letter-spacing: 0.5px;
      line-height: 44px;
      margin-left: 25px;
      padding: 0;
      position: relative;
      text-indent: 0;
      top: -9px;
      width: 154px;
      @include media-breakpoint-down(lg) {
        @include font-size (16px, 18px);
        border-radius: 20px;
        height: 38px;
        line-height: 38px;
        margin-left: 20px;
        padding: 0 24px;
      }
      @include media-breakpoint-down(md) {
        margin: 0 auto;
      }

      &:hover {
        background: #000;
        color: #fff;
      }
    }
  }

  &__text {
    @include font-size (16px, 18px);
    font-weight: normal;
    margin-top: -5px;
    text-indent: 21px;
    @include media-breakpoint-down(md) {
      text-align: center;
    } 
  }
}

/* stylelint-disable */
.promo .container {
  max-width: 955px;
  margin: 0 auto;
}
