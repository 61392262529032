/* stylelint-disable plugin/selector-bem-pattern */

/** @define nav-wrapper */
.nav-wrapper {
  float: right;
  margin-right: -33px;
  margin-top: 14px;
  position: relative;
}
/** @define navbar */
.navbar {
  @include font-size(18px, 19px);
  font-weight: 500;
  min-height: 1px;
  padding: 0;

  li {
    display: inline-block;
    margin: 0 71px 0 0;
    padding: 0;
    vertical-align: top;

    @media screen and (max-width:1449px) {
      margin-right: 30px;
    }

    &:first-child {
      border-left: 0;
    }

    a {
      color: $gray;
      display: block;
      padding: 0;
      position: relative;
      text-decoration: none;

      &:hover {
        color: #649dc8;
      }
    }
  }

  .btn {
    margin-right: 10px;
  }

  .btn a {
    background: #41653d;
    border-radius: 30px;
    color: #fff;
    font-size: 18px;
    height: 44px;
    line-height: 44px;
    margin-top: 8px;
    padding: 0 31px;

    @media screen and (max-width:1449px) {
      font-size: 16px;
      padding: 0 21px;
    }

    &:hover {
      background: #000;
    }
  }
}

@include media-breakpoint-down(lg) {
  .navbar-collapse {
    background: #f6f6f6;
    padding: 0;
    position: absolute;
    right: 0;
    top: 25px;
  }

  .navbar-collapse ul {
    min-width: 220px;
  }

  .navbar-collapse ul li {
    border-bottom: 1px solid;
    display: block;
    font-size: 14px;
    height: auto;
    margin: 0;
    padding: 7px 20px;
    vertical-align: top;
    white-space: inherit;
  }

  /* stylelint-disable */
  .navbar-collapse ul li.btn a {
    height: 35px;
    line-height: 35px;
    padding: 0 20px;
  }
}

.navbar-collapse .btn {
  margin-top: -23px;
}
.navbar-collapse.show .navbar-collapse {
  display: block;
}
.navbar-collapse.show .btn {
    margin-top: -7px !important;
}
.navbar-toggler {
  margin-right: 20px;
}
.collapse.show {
  z-index: 9;
}
