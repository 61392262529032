/** @define testimonialarea */
.testimonialarea {
  background: #a2c15c;
  border-top: 6px solid #fffb9a;
  display: block;
  padding: 60px 0 40px;

  &__left {
    @include font-size (57px, 74px);
    color: #fff;
    float: left;
    font-family: $otari;
    font-weight: normal;
    letter-spacing: 0.5px;
    margin-right: 68px;
    margin-top: 40px;
    max-width: 694px;
    text-align: center;

    @media screen and (max-width:1449px) {
      @include font-size (47px, 60px);
      letter-spacing: none;
      margin-right: 20px;
      max-width: 45%;
    }

    @include media-breakpoint-down(md) {
      @include font-size (40px, 52px);
      float: none;
      margin: 0 0 20px;
      max-width: 100%;
      width: 100%;
    }
    @include media-breakpoint-down(md) {
      @include font-size (35px, 45px);
    }   
  }
}

/** @define testibox */
.testibox {
  background: #649dc8;
  border-radius: 20px;
  padding: 34px 52px;
  position: relative;
  @include media-breakpoint-down(md) {
    float: none;
    max-width: 100%;
    width: 100%;
  }

  &__head {
    @include font-size (50px, 50px);
    color: #fff;
    display: block;
    font-family: $otari;
    margin-bottom: 12px;
    @include media-breakpoint-down(md) {
      @include font-size (40px, 50px);
    }
    @include media-breakpoint-down(sm) {
      @include font-size (30px, 40px);
    }
  }

  &__detail {
    @include font-size (18px, 26px);
    color: #fff;
    position: relative;
    text-indent: 37px;
  }

  &__author {
    @include font-size (25px, 27px);
    color: #fff;
    float: right;
    font-family: $otari;
    margin-right: 5px;
    margin-top: 14px;
    @include media-breakpoint-down(sm) {
      float: none;
    }
  }

  &__readmore {
    @include font-size (18px, 44px);
    background: #41653d;
    border-radius: 30px;
    color: #fff;
    display: block;
    font-family: $proximasoft;
    font-weight: bold;
    height: 44px;
    margin-top: 20px;
    text-align: center;
    width: 154px;

    &:hover {
      background: #000;
      color: #fff;
    }
  }
}

/* stylelint-disable */
.testiboxwrap {
  overflow: hidden;
  padding-bottom: 40px;
}
.testimonialarea .container {
  margin: 0 auto;
  max-width: 1550px;
  overflow: hidden;
  @media screen and (max-width:1449px) {
    max-width: 96%;
  }
}
.testibox__readmore:hover {
  background: #000;
}
.testibox__detail::before {
  background: url(../img/quote-ico-left.jpg) no-repeat;
  content: '';
  height: 19px;
  left: 0;
  position: absolute;
  top: 0;
  width: 22px;
}
.quote-right {
  display: inline-block;
  margin-left: 5px;
  text-indent: 0;
}
.testibox::after{
  border-color: #649dc8 transparent transparent transparent;
  border-style: solid;
  border-width: 39px 91px 0 0px;
  bottom: -39px;
  content: '';
  height: 0;
  left: 334px;
  position: absolute;
  width: 0;
  @include media-breakpoint-down(sm) {
    left: 10%;
  }
}