/** @define wrapper */
.wrapper {
  overflow: hidden;
  position: relative;
}

/** @define header */
.header {
  background: $gray2;
  border-top: 3px solid #fffb9a;
  display: table;
  padding: 15px 0 12px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 11;
  @include media-breakpoint-down(md) {
    position: relative;
  }
}

/** @define logo */
.logo {
  background: $gray2;
  border-radius: 50%;
  float: left;
  margin-top: -14px;
  padding: 20px;
  position: absolute;
  width: 200px;
  @include media-breakpoint-down(md) {
    margin-top: 0;
    padding-top: 0;
  }

  &__img {
    display: block;
    height: auto;
    max-width: 180px;
    width: 100%;
  }
}

/* stylelint-disable */
.headerlist li:nth-child(2) {
  border-left:1px solid $gray;
}
.header .container {
    max-width: 1545px;
    margin: 0 auto;
    @media screen and ( max-width:1590px ) {
      max-width: 96%;
    }
  }
  @include media-breakpoint-up(md) {
    .mob {
      display: none !important;
    }
    .des {
      display: inline-block !important;
    }
  }
  @include media-breakpoint-down(md) {
      .des {
        display: none !important;
      }
      .mob {
        display: table !important;
      }
      .logo {
        width: 150px;
      }
    }
