// custom font mixin
@mixin fontface ($fontfamily, $filename, $weight, $style) {
  font-family: $fontfamily;
  font-style: $style;
  font-weight: $weight;
  src: url('../fonts/#{$filename}.eot');
  src: url('../fonts/#{$filename}.eot?#iefix') format('embedded-opentype'),
    url('../fonts/#{$filename}.woff') format('woff'),
    url('../fonts/#{$filename}.ttf') format('truetype'),
    url('../fonts/#{$filename}.svg#{$filename}') format('svg');
}
