/** @define dearparents */
.dearparents {
  display: flex;
  margin-bottom: 70px;
  @include media-breakpoint-down(lg) {
    margin-bottom: 50px;
  }

  &__image {
    float: left;
    margin-left: -81px;
    @include media-breakpoint-down(lg) {
      margin-left: 0;
      max-width: 45%;
    }
    @include media-breakpoint-down(md) {
      float: none;
      max-width: 100%;
      width: 100%;
    }
  }

  &__text {
    color: #fff;
    overflow: hidden;
    padding: 0 55px 0 40px;
    @include media-breakpoint-down(lg) {
      padding: 0 40px 0 30px;
    }

    &--head {
      @include font-size (78px, 79px);
      font-family: $halohand;
      font-weight: normal;
      margin: 48px 0 9px;
      @include media-breakpoint-down(lg) {
        @include font-size (68px, 69px);
        margin: 38px 0 7px;
      }
      @include media-breakpoint-down(md) {
        float: none;
        max-width: 100%;
        width: 100%;
      }
    }
  }
}
/** @define signaturearea */
.signaturearea {
  @include font-size (50px, 52px);
  display: block;
  font-family: $halohand;
  font-weight: normal;
  margin-top: 33px;
  @include media-breakpoint-down(lg) {
    @include font-size (40px, 42px);
  }
}

/* stylelint-disable */

.dearparents .container {
  max-width: 1225px;
  background: #649dc8;
}
.dearparents__text p {
  @include font-size (18px, 25px);
  margin-bottom: 30px;
  margin-top: 0;
  padding-top: 0;
}
.signaturearea span {
  @include font-size (18px, 25px);
  font-family: $proximasoft;
  display: block;
}
 @include media-breakpoint-down(lg) {
  .dearparents__image img {
    max-width: 100%;
    height: auto;
  }
 }

@media (max-width: 1380px) {
  .dearparents__image {
    margin-left: 0 !important;
    max-width: 50%;
  }
  .dearparents__image img {
    max-width: 100%;
  }
}
@media (max-width: 992px) {
  .dearparents__image {
    max-width: 100% !important;
  }
}