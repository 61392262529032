/** @define footer */
.footer {
  background: #649dc8;
  color: #fff;
  display: block;
  overflow: hidden;
  text-align: center;

  &__left {
    display: inline-block;
    float: left;
    max-width: 250px;
    text-align: left;
    @include media-breakpoint-down(md) {
      display: block;
      float: none;
      margin: 0 0 20px;
      max-width: 100%;
      text-align: center;
    }

    &--head {
      @include font-size (25px, 28px);
      color: #fff;
      display: block;
      font-family: $otari;
      margin-bottom: 20px;
    }
  }

  &__center {
    @include font-size (23px, 42px);
    display: inline-block;
    font-style: italic;
    left: 6px;
    letter-spacing: 0.2px;
    max-width: 418px;
    position: relative;
    text-align: center;
    top: -7px;
    @include media-breakpoint-down(md) {
      display: block;
      float: none;
      margin: 0 0 20px;
      max-width: 100%;
      text-align: center;
    }
  }

  &__right {
    display: inline-block;
    float: right;
    max-width: 250px;
    text-align: right;
    @include media-breakpoint-down(md) {
      display: block;
      float: none;
      margin: 0 0 20px;
      max-width: 100%;
      text-align: center;
    }
  }
}

/** @define social-icon */
.social-icon {
  display: block;
  float: right;
  height: 49px;
  width: 49px;
  @include media-breakpoint-down(md) {
    float: none;
    margin: 0 auto;
  }

  &:hover {
    opacity: 0.7;
  }
}

/* stylelint-disable */

.footer .container {
  max-width: 1118px;
  margin: 0 auto;
  padding: 38px 0 18px;
  @include media-breakpoint-down(lg) {
    max-width: 96%;
  }
}
.footer__right p {
  margin-bottom: 23px;
  overflow: hidden;
}
.footer__center span {
  display: block;
  margin-top: 9px;
  font-size: 16px;
  font-family: $proximasoft;
  font-style: normal;
}
.footer_img_big {
  width: 100%;
  height: auto;
}
.footer__left p a {
  color: #fff;
}
.footer__left p a:hover {
  color: #0056b3;
}
.footer-internal {
  display: none;
}
body:not(.home) .footer__center {
  display: none;
}
body:not(.home) .footer-internal .container {
  max-width: 1200px;
}
body:not(.home) .footer-internal {
  padding: 38px 0;
  background: #a2c15c;
  text-align: center;
  font-family: $otari;
  display: block;
}
body:not(.home) .footer-internal em {
  font-size: 39px;
  line-height: 45px;
  font-weight: bold;
  color: #fff;
  font-style: normal;
  display: block;
  text-align: center;
  margin-bottom: 15px;
}
body:not(.home) .footer-internal span {
  font-size: 30px;
  color: #fff;
}