/* stylelint-disable plugin/selector-bem-pattern */

/**
 * This File should select on basic elements that are not specific to components. Below are common use cases
 */

/* Set default styling for links on site */
/* change color and transition example
a {
  color: #000;
  transition: all 0.4s ease;
  &:hover {
    color: #f5502a;
    text-decoration: none;
  }
  
}
*/

/* Override bootstrap button styles here: (first set related colors/variables in _variables.scss file) */
/* make buttons have rounded edges example
.btn {
  border-radius: 12px;
}


/**
 * Wordpress Specific Section
 * This section holds all the specific CSS needed to make wordpress content editor/ACF work nicely.
 */

/* Set white-space to pre-line to make using &#10; possible to control line breaks without <br/> tags or changing other behavior*/

/* stylelint-disable */
body {
  background: #fffee6;
  font-size: 18px;
  line-height: 25px;
  font-family: "proxima-soft",sans-serif;
  min-width: 320px;
  font-weight: normal;
  border-top: 3px solid #fffb9a;
}
body.home {
  background: #fff;
}
 ::-webkit-input-placeholder { 
  color: $gray3;
}
:-moz-placeholder { 
  color: $gray3;
}
::-moz-placeholder { 
  color: $gray3;
  opacity: 1;
}
:-ms-input-placeholder { 
  color: $gray3;
}
a { 
  color: #000;
  transition: all 0.4s ease;
  text-decoration: none !important;
}


h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 { 
  font-weight: 700;
  margin-top: 0;
}
p,
li,
a { 
  margin-top: 0;
}
.gform_footer input[type="submit"] {
    background: #41653d;
    border-radius: 30px;
    color: #fff;
    font-size: 18px;
    height: 44px;
    line-height: 44px;
    margin-top: 8px;
    padding: 0 31px !important;
    border: none;
    cursor: pointer;

    &:hover {
      background: #000;
      color: #fff;
    }
}
.page-content img {
  max-width: 100%;
  height: auto;
}
.gform_ajax_spinner {
  display: none !important;
}