/** @define galleryarea */
.galleryarea {
  display: block;
  margin-bottom: 70px;
  @include media-breakpoint-down(md) {
    margin-bottom: 50px;
  }
  
  @include media-breakpoint-down(sm) {
    margin-bottom: 30px;
  }

  &__left {
    float: left;
    position: relative;
    width: 50%;

    &::after {
      background: #649dc8;
      content: '';
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      width: 14px;
    }
  }

  &__right {
    float: right;
    position: relative;
    width: 50%;

    &::before {
      background: #a2c15c;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 14px;
    }
  }

  &__btn {
    @include font-size (18px, 44px);
    background: #41653d;
    border-radius: 30px;
    color: #fff;
    display: block;
    font-weight: bold;
    height: 44px;
    position: absolute;
    text-align: center;
    width: 155px;

    &:hover {
      background: #000;
      color: #fff;
    }
  }
}

/* stylelint-disable */
.galleryarea .container {
  align-items: center;
  display: flex;
  height: auto;
  justify-content: center;
  margin: 0 auto;
  max-width: 1225px;
  overflow: hidden;
  padding: 0;
  width: 100%;
}
.galleryarea__btn:before {
  border-bottom: 10px solid #649dc8;
  border-bottom-left-radius: 48%;
  border-left: 10px solid #649dc8;
  border-top: 10px solid #649dc8;
  border-top-left-radius: 47%;
  bottom: -9px;
  content: "";
  height: calc(100% + 18px);
  left: 0;
  overflow: hidden;
  position: absolute;
  top: -9px;
  width: 50%;
  z-index: 1;
}
.galleryarea__btn:after {
  border-bottom: 10px solid #a2c15c;
  border-bottom-right-radius: 48%;
  border-right: 10px solid #a2c15c;
  border-top: 10px solid #a2c15c;
  border-top-right-radius: 47%;
  bottom: -9px;
  content: "";
  height: calc(100% + 18px);
  right: 0;
  overflow: hidden;
  position: absolute;
  top: -9px;
  width: 50%;
  z-index: 1;
}
.galleryarea__left img,
.galleryarea__right img {
  max-width: 100%;
  height: auto;
}

.gallery .gallery-item {
  width: 23%;
  display: inline-block;
  margin: 0 1% 25px;
}
.gallery .gallery-item img {
  width: 100%;
  height: auto;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .galleryarea .container {
    position: relative;
  }
  .galleryarea__btn {
    left: 535px;
  }
}