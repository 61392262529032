/** @define visual-internal */
.visual-internal {
  display: block;

  &__img {
    display: block;
    height: auto;
    width: 100%;
  }
}

/** @define promo-internal */
.promo-internal {
  @include font-size(50px, 51px);
  background: #a2c15c;
  color: #fff;
  display: block;
  font-family: $otari;
  padding: 29px 0;
  text-align: center;
  @include media-breakpoint-down(md) {
    @include font-size(30px, 33px);
  }
}

/** @define page-content */
.page-content {
  background: #fffee6;
  margin: 0 auto;
  max-width: 1080px;
  padding: 65px 0;
  @include media-breakpoint-down(lg) {
    max-width: 96%;
    padding: 35px 0;
  }

  &__quote {
    @include font-size(30px, 35px);
    border-bottom: 1px solid #b8b8a9;
    color: #000;
    font-weight: 700;
    padding-bottom: 50px;
    white-space: normal;
    @include media-breakpoint-down(md) {
      @include font-size(20px, 23px);
    }

    &--author {
      @include font-size(25px, 30px);
      display: inline;
      @include media-breakpoint-down(md) {
        @include font-size(16px, 18px);
      }
    }
  }
}

/** @define contblock */
.contblock {
  align-items: center;
  display: flex;
  margin-top: 50px;
  padding-bottom: 50px;
  width: 100%;
  @include media-breakpoint-down(md) {
    display: block;
    width: 100%;
  }

  &__image {
    border-radius: 50%;
    margin-right: 40px;
    max-width: 362px;
    position: relative;
    @include media-breakpoint-down(md) {
      float: none;
      margin: 0 auto 15px;
      max-width: 280px;
    }

    &::before {
      border-radius: 100%;
      box-shadow: 15px 15px 0 #649dc8;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }
  }

  &__content {
    @include font-size(18px, 25px);
    color: #000;
    overflow: hidden;
    width: 100%;
    @include media-breakpoint-down(md) {
      float: none;
      margin: 0 0 15px;
      max-width: 100%;
      width: 100%;
    }
  }
}

/** @define contblocktop */
.contblocktop {
  margin-bottom: 28px;
  overflow: hidden;
  width: 100%;

  &__secleft {
    float: left;
    margin-right: 40px;
    max-width: 317px;
    @include media-breakpoint-down(md) {
      float: none;
      margin: 0 0 15px;
      max-width: 100%;
      width: 100%;
    }
  }

  &__secright {
    overflow: hidden;
    @include media-breakpoint-down(md) {
      float: none;
      margin: 0 0 15px;
      max-width: 100%;
      width: 100%;
    }
  }
}



/* stylelint-disable */
.promo-internal h1 {
  position: relative;
  display: table;
  margin: 0 auto;
}
.promo-internal h1::before {
  position: absolute;
  content: '';
  width: 29px;
  height: 28px;
  background: url('../img/star.jpg') no-repeat;
  left: -145px;
  top: 5px;
  @include media-breakpoint-down(md) {
     left: -45px;
  }
}
.promo-internal h1::after {
  position: absolute;
  content: '';
  width: 29px;
  height: 28px;
  background: url('../img/star.jpg') no-repeat;
  right: -145px;
  top: 5px;
  @include media-breakpoint-down(md) {
     right: -45px;
  }
}
.contblock__image img {
  position: relative;
  z-index: 0;
}
.contblock h2 {
  @include font-size(25px, 28px);
  color: #000;
  font-family: $proximasoft;
  font-weight: 700;
  margin-bottom: 30px;
}
.contblocktop h2 {
  margin-bottom: 15px;
}
.contblock__content ul {
  margin: 0 0 0 4px;
}
.contblock__content ul li {
  padding-left: 15px;
}
.imgshadowgreen::before {
    border-radius: 100%;
    box-shadow: 15px 15px 0 #a2c15c;
  }
.contblock:last-child {
  border-bottom: none;
}
.contblock__image img {
  width: 100%;
  height: auto;
}
.speprograms {
  margin-bottom: -42px;
  margin-left: 305px;
  margin-top: 50px;
  font-size: 35px;
  color: #000;
  @include media-breakpoint-down(md) {
    margin: 40px auto;
    display: table;
  }
}
body:not(.home) .header {
  position: static;
}